<template>
  <div>
    <v-snackbar v-model="showSnackBar" color="#68D389" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: #ffffff">
            {{ msg }}
          </span>
        </v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #ffffff">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-start>
      <v-flex xs12 py-12>
        <v-divider></v-divider>
      </v-flex>
      <v-flex xs12 sm2 pa-2>
        <v-card
          color="#000000"
          height="50px"
          width="100%"
          outlined
          elevation="8"
          @click="mystockAdder('new')"
        >
          <v-layout wrap justify-center fill-height>
            <v-flex xs6 align-self-center>
              <span
                style="
                  font-family: opensansbold;
                  font-size: 14px;
                  color: #ffffff;
                "
              >
                Add
              </span>
            </v-flex>
            <v-flex xs4 align-self-center>
              <v-icon size="14px" color="#ffffff">mdi-plus</v-icon>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
      <v-flex xs12 pa-3 v-if="flagmain">
        <v-layout
          wrap
          v-if="units.length > 0"
          style="font-family: poppinsregular"
        >
          <v-flex xs12 lg6 v-for="(main, j) in units" :key="j" pa-4>
            <v-card outlined>
              <v-layout wrap pa-2>
                <v-flex xs12 align-self-center text-right>
                  <v-icon @click.prevent="units.splice(j, 1)" color="#FF1313"
                    >mdi-close</v-icon
                  >
                </v-flex>
                <v-flex xs12 lg12 align-self-center text-left>
                  <span class="prodTitle">{{ main.type }}</span>
                </v-flex>
                <v-flex xs12 lg4 align-self-center text-left v-if="main.price">
                  <span class="prodSub">Price</span> <br />
                  <span>{{ main.price }}</span>
                </v-flex>
                <v-flex xs12 lg4 align-self-center text-left v-if="main.stock">
                  <span class="prodSub">Stock</span> <br />
                  <span>{{ main.stock }}</span>
                </v-flex>
                <v-flex xs12 lg4 align-self-center text-left v-if="main.weight">
                  <span class="prodSub">Weight</span> <br />
                  <span>{{ main.weight }}</span>
                </v-flex>
              </v-layout>
              <v-layout wrap v-if="main.size" pa-2>
                <v-flex xs12 py-2>
                  <v-divider></v-divider>
                </v-flex>
                <v-flex xs12 lg6 v-for="(subs, k) in main.size" :key="k" pa-2>
                  <v-card outlined>
                    <v-layout wrap pa-2>
                      <v-flex xs12 align-self-center text-right>
                        <v-icon
                          @click.prevent="main.size.splice(k, 1)"
                          color="#FF1313"
                          >mdi-close</v-icon
                        >
                      </v-flex>
                      <v-flex xs12 lg12 align-self-center text-left>
                        <span class="prodMain">{{ subs.sizename }}</span>
                      </v-flex>
                      <v-flex xs12 lg4 align-self-center text-left>
                        <span class="prodSub">Price</span> <br />
                        <span>{{ subs.price }}</span>
                      </v-flex>
                      <v-flex xs12 lg4 align-self-center text-left>
                        <span class="prodSub">Stock</span> <br />
                        <span>{{ subs.stock }}</span>
                      </v-flex>
                      <v-flex xs12 lg4 align-self-center text-left>
                        <span class="prodSub">Weight</span> <br />
                        <span>{{ subs.weight }}</span>
                      </v-flex>
                      <v-flex xs12 align-self-center text-right>
                        <v-btn
                          tile
                          dark
                          small
                          color="green"
                          depressed
                          class="text-capitalize"
                          :ripple="false"
                          @click="editSub(subs, k, j)"
                        >
                          Edit
                        </v-btn>
                      </v-flex>
                    </v-layout>
                  </v-card>
                </v-flex>
                <v-flex
                  xs12
                  lg3
                  px-2
                  align-self-center
                  text-right
                  v-if="main.size.length > 0"
                >
                  <v-btn
                    tile
                    dark
                    small
                    color="blue"
                    depressed
                    class="text-capitalize"
                    :ripple="false"
                    @click="addMoreSub(j)"
                  >
                    Add More Sub
                  </v-btn>
                </v-flex>
                <v-flex xs12 lg3 px-2 align-self-center text-right>
                  <v-btn
                    tile
                    dark
                    small
                    color="green"
                    depressed
                    class="text-capitalize"
                    :ripple="false"
                    @click="editMain(main, j)"
                  >
                    Edit Main Type
                  </v-btn>
                </v-flex>
                <!-- <v-flex xs12>
                  <v-divider></v-divider>
                </v-flex> -->
              </v-layout>
            </v-card>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex xs12 py-12>
        <v-divider></v-divider>
      </v-flex>
    </v-layout>
    <!-- jmj -->
    <v-dialog
      v-model="firstDialog"
      max-width="500px"
      transition="dialog-transition"
      persistent
    >
      <v-layout wrap justify-center>
        <v-flex xs12>
          <!-- <v-form v-model="firstForm" ref="firstForm"> -->
          <v-card>
            <v-layout wrap justify-center pa-4>
              <v-flex xs12>
                <v-layout wrap justify-center pb-4>
                  <v-flex xs12>
                    <span
                      style="
                        font-family: poppinssemibold;
                        font-size: 20px;
                        color: #000000;
                      "
                      >Add Types</span
                    >
                  </v-flex>
                </v-layout>

                <v-layout wrap justify-start py-2>
                  <v-flex xs6 sm6 px-2 text-left>
                    <span class="styloopinputlabel"> Type Name </span>
                    <v-text-field
                      outlined
                      dense
                      color="#FF1313"
                      placeholder="Type Name"
                      v-model="typeObject.type"
                      class="text-left"
                    >
                    </v-text-field>
                  </v-flex>

                  <v-flex xs6 sm6 px-2 text-left v-if="!isSubCheck">
                    <span class="styloopinputlabel"> Stock </span>
                    <v-text-field
                      v-model="typeObject.stock"
                      :disabled="isSubCheck ? true : false"
                      type="number"
                      color="#FF1313"
                      hide-selected
                      outlined
                      placeholder="Available Stock"
                      dense
                      hide-details
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs6 sm6 px-2 text-left v-if="!isSubCheck">
                    <span class="styloopinputlabel"> Price </span>
                    <v-text-field
                      v-model="typeObject.price"
                      :disabled="isSubCheck ? true : false"
                      type="number"
                      color="#FF1313"
                      outlined
                      placeholder="Available Price"
                      dense
                      hide-details
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs6 sm6 px-2 text-left v-if="!isSubCheck">
                    <span class="styloopinputlabel"> Weight </span>
                    <v-text-field
                      v-model="typeObject.weight"
                      :disabled="isSubCheck ? true : false"
                      type="number"
                      color="#FF1313"
                      outlined
                      placeholder="Weight of product"
                      dense
                      hide-details
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 py-4>
                    <v-checkbox
                      v-if="typeObject.type ? true : (isSubCheck = false)"
                      :disabled="typeObject.type ? false : true"
                      v-model="isSubCheck"
                      label="Add Sub Types?"
                    ></v-checkbox>
                  </v-flex>
                  <v-flex xs12 align-self-center pa-3 v-if="flaggy">
                    <v-layout wrap>
                      <v-flex
                        xs12
                        v-for="(item, i) in typeObject.size"
                        :key="i"
                        align-self-center
                      >
                        <v-card outlined>
                          <v-layout wrap pa-4>
                            <v-flex xs12 py-2 text-left>
                              <v-divider></v-divider>
                            </v-flex>
                            <v-flex
                              lg4
                              text-left
                              class="prodMain"
                              style="color: grey; font-size: 14px"
                            >
                              Sub Type
                            </v-flex>
                            <v-flex lg8 text-left>
                              <span class="prodSub"> {{ item.sizename }}</span>
                            </v-flex>

                            <v-flex xs12 py-2>
                              <v-divider></v-divider>
                            </v-flex>
                            <v-flex lg4 text-left
                              ><span
                                class="prodMain"
                                style="color: grey; font-size: 14px"
                              >
                                Sub Type Price(INR)
                              </span>
                            </v-flex>
                            <v-flex lg8 text-left>
                              <span class="prodSub">{{ item.price }}</span>
                            </v-flex>

                            <v-flex xs12 py-2>
                              <v-divider></v-divider>
                            </v-flex>
                            <v-flex
                              lg4
                              text-left
                              class="prodMain"
                              style="color: grey; font-size: 14px"
                            >
                              Sub Type Stock
                            </v-flex>
                            <v-flex lg8 text-left class="prodSub">
                              {{ item.stock }}
                            </v-flex>
                            <v-flex xs12 py-2>
                              <v-divider></v-divider>
                            </v-flex>

                            <v-flex
                              lg4
                              text-left
                              class="prodMain"
                              style="color: grey; font-size: 14px"
                            >
                              Sub Type Weight
                            </v-flex>
                            <v-flex lg8 text-left class="prodSub">
                              {{ item.weight }}
                            </v-flex>

                            <v-flex xs12 py-2>
                              <v-divider></v-divider>
                            </v-flex>
                          </v-layout>
                        </v-card>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12 py-2 v-if="isSubCheck">
                    <v-btn
                      tile
                      block
                      dark
                      color="#68D389"
                      depressed
                      class="text-capitalize"
                      :ripple="false"
                      @click="(secondDialog = true), reUp()"
                    >
                      Add Sub Types
                    </v-btn>
                  </v-flex>
                </v-layout>
                <v-layout wrap justify-end py-4>
                  <v-flex xs6 sm4 px-2>
                    <v-btn
                      block
                      tile
                      color="#757575"
                      outlined
                      :ripple="false"
                      depressed
                      @click="firstDialog = false"
                    >
                      <span
                        style="
                          font-family: kumbhsansregular;
                          font-size: 16px;
                          text-transform: none;
                          color: #000000;
                        "
                      >
                        Cancel
                      </span>
                    </v-btn>
                  </v-flex>
                  <v-flex xs6 sm4 px-2>
                    <v-btn
                      tile
                      block
                      dark
                      color="#FF1313"
                      depressed
                      class="text-capitalize"
                      :ripple="false"
                      @click="validateFirst()"
                    >
                      Apply
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-card>
          <!-- </v-form> -->
        </v-flex>
      </v-layout>
    </v-dialog>

    <!-- editMain  -->
    <v-dialog
      v-model="firstEditDialog"
      max-width="500px"
      transition="dialog-transition"
      persistent
    >
      <v-layout wrap justify-center>
        <v-flex xs12>
          <v-card>
            <v-layout wrap justify-center pa-4>
              <v-flex xs12>
                <v-layout wrap justify-center pb-4>
                  <v-flex xs12>
                    <span
                      style="
                        font-family: poppinssemibold;
                        font-size: 20px;
                        color: #000000;
                      "
                      >Edit Type</span
                    >
                  </v-flex>
                </v-layout>

                <v-layout wrap justify-start>
                  <v-flex xs6 sm4 px-2 text-left>
                    <span class="styloopinputlabel"> Type Name </span>
                    <v-text-field
                      outlined
                      dense
                      color="#FF1313"
                      placeholder="Type Name"
                      v-model="editMainItem.type"
                      class="text-left"
                    >
                    </v-text-field>
                  </v-flex>

                  <v-flex xs6 sm4 px-2 text-left>
                    <span class="styloopinputlabel"> Stock </span>
                    <v-text-field
                      v-model="editMainItem.stock"
                      :disabled="isSubCheck ? true : false"
                      type="number"
                      color="#FF1313"
                      hide-selected
                      outlined
                      placeholder="Available Stock"
                      dense
                      hide-details
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs6 sm4 px-2 text-left>
                    <span class="styloopinputlabel"> Price(INR)</span>
                    <v-text-field
                      v-model="editMainItem.price"
                      :disabled="isSubCheck ? true : false"
                      type="number"
                      color="#FF1313"
                      outlined
                      placeholder="Available Price"
                      dense
                      hide-details
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs6 sm4 px-2 text-left>
                    <span class="styloopinputlabel"> Weight </span>
                    <v-text-field
                      v-model="editMainItem.weight"
                      :disabled="isSubCheck ? true : false"
                      type="number"
                      color="#FF1313"
                      outlined
                      placeholder="Weight of product"
                      dense
                      hide-details
                    ></v-text-field>
                  </v-flex>
                </v-layout>
                <v-layout wrap justify-end py-4>
                  <v-flex xs6 sm4 px-2>
                    <v-btn
                      block
                      tile
                      color="#757575"
                      outlined
                      :ripple="false"
                      depressed
                      @click="firstEditDialog = false"
                    >
                      <span
                        style="
                          font-family: kumbhsansregular;
                          font-size: 16px;
                          text-transform: none;
                          color: #000000;
                        "
                      >
                        Cancel
                      </span>
                    </v-btn>
                  </v-flex>
                  <v-flex xs6 sm4 px-2>
                    <v-btn
                      tile
                      block
                      dark
                      color="#FF1313"
                      depressed
                      class="text-capitalize"
                      :ripple="false"
                      @click="editTypeObject()"
                    >
                      Apply
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-card>
        </v-flex>
      </v-layout>
    </v-dialog>
    <!-- secondDialog  -->

    <v-dialog
      v-model="secondDialog"
      max-width="500px"
      transition="dialog-transition"
      persistent
    >
      <v-layout wrap justify-center>
        <v-flex xs12>
          <v-card>
            <v-layout wrap justify-center pa-4>
              <v-flex xs12>
                <v-layout wrap justify-center pb-4>
                  <v-flex xs12>
                    <span
                      style="
                        font-family: poppinssemibold;
                        font-size: 20px;
                        color: #000000;
                      "
                      >Add Sub Types</span
                    >
                  </v-flex>
                </v-layout>

                <v-layout wrap justify-start py-2>
                  <v-flex xs6 sm6 px-2 text-left>
                    <span class="styloopinputlabel"> SubType Name </span>
                    <v-text-field
                      outlined
                      dense
                      color="#FF1313"
                      placeholder="SubType Name"
                      v-model="subtypeObject.sizename"
                      class="text-left"
                    >
                    </v-text-field>
                  </v-flex>

                  <v-flex xs6 sm6 px-2 text-left>
                    <span class="styloopinputlabel"> Stock </span>
                    <v-text-field
                      v-model="subtypeObject.stock"
                      type="number"
                      color="#FF1313"
                      hide-selected
                      outlined
                      placeholder="Available Stock"
                      dense
                      hide-details
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs6 sm6 px-2 text-left>
                    <span class="styloopinputlabel"> Price(INR) </span>
                    <v-text-field
                      v-model="subtypeObject.price"
                      type="number"
                      color="#FF1313"
                      outlined
                      placeholder="Available Price"
                      dense
                      hide-details
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs6 sm6 px-2 text-left>
                    <span class="styloopinputlabel"> Weight </span>
                    <v-text-field
                      v-model="subtypeObject.weight"
                      type="number"
                      color="#FF1313"
                      outlined
                      placeholder="Weight of product"
                      dense
                      hide-details
                    ></v-text-field>
                  </v-flex>
                </v-layout>
                <v-layout wrap justify-end py-4>
                  <v-flex xs6 sm4 px-2>
                    <v-btn
                      block
                      tile
                      color="#757575"
                      outlined
                      :ripple="false"
                      depressed
                      @click="secondDialog = false"
                    >
                      <span
                        style="
                          font-family: kumbhsansregular;
                          font-size: 16px;
                          text-transform: none;
                          color: #000000;
                        "
                      >
                        Cancel
                      </span>
                    </v-btn>
                  </v-flex>
                  <v-flex xs6 sm4 px-2 v-if="subflag">
                    <v-btn
                      tile
                      block
                      dark
                      color="#FF1313"
                      depressed
                      class="text-capitalize"
                      :ripple="false"
                      @click="addMoreSubTypeObject()"
                    >
                      Apply
                    </v-btn>
                  </v-flex>
                  <v-flex xs6 sm4 px-2 v-else>
                    <v-btn
                      tile
                      block
                      dark
                      color="#FF1313"
                      depressed
                      class="text-capitalize"
                      :ripple="false"
                      @click="validateSecond()"
                    >
                      Apply
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-card>
        </v-flex>
      </v-layout>
    </v-dialog>

    <!-- Edit  -->
    <v-dialog
      v-model="editDialog"
      max-width="500px"
      transition="dialog-transition"
      persistent
    >
      <v-layout wrap justify-center>
        <v-flex xs12>
          <v-card>
            <v-layout wrap justify-center pa-4>
              <v-flex xs12>
                <v-layout wrap justify-center pb-4>
                  <v-flex xs12>
                    <span
                      style="
                        font-family: poppinssemibold;
                        font-size: 20px;
                        color: #000000;
                      "
                      >Edit Sub Type</span
                    >
                  </v-flex>
                </v-layout>

                <v-layout wrap justify-start>
                  <v-flex xs6 sm4 px-2 text-left>
                    <span class="styloopinputlabel"> SubType Name </span>
                    <v-text-field
                      outlined
                      dense
                      color="#FF1313"
                      placeholder="SubType Name"
                      v-model="editSubItem.sizename"
                      class="text-left"
                    >
                    </v-text-field>
                  </v-flex>

                  <v-flex xs6 sm4 px-2 text-left>
                    <span class="styloopinputlabel"> Stock </span>
                    <v-text-field
                      v-model="editSubItem.stock"
                      type="number"
                      color="#FF1313"
                      hide-selected
                      outlined
                      placeholder="Available Stock"
                      dense
                      hide-details
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs6 sm4 px-2 text-left>
                    <span class="styloopinputlabel"> Price(INR) </span>
                    <v-text-field
                      v-model="editSubItem.price"
                      type="number"
                      color="#FF1313"
                      outlined
                      placeholder="Available Price"
                      dense
                      hide-details
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs6 sm4 px-2 text-left>
                    <span class="styloopinputlabel"> Weight </span>
                    <v-text-field
                      v-model="editSubItem.weight"
                      type="number"
                      color="#FF1313"
                      outlined
                      placeholder="Weight of product"
                      dense
                      hide-details
                    ></v-text-field>
                  </v-flex>
                </v-layout>
                <v-layout wrap justify-end py-4>
                  <v-flex xs6 sm4 px-2>
                    <v-btn
                      block
                      tile
                      color="#757575"
                      outlined
                      :ripple="false"
                      depressed
                      @click="editDialog = false"
                    >
                      <span
                        style="
                          font-family: kumbhsansregular;
                          font-size: 16px;
                          text-transform: none;
                          color: #000000;
                        "
                      >
                        Cancel
                      </span>
                    </v-btn>
                  </v-flex>
                  <v-flex xs6 sm4 px-2>
                    <v-btn
                      tile
                      block
                      dark
                      color="#FF1313"
                      depressed
                      class="text-capitalize"
                      :ripple="false"
                      @click="editSubTypeObject()"
                    >
                      Apply
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-card>
        </v-flex>
      </v-layout>
    </v-dialog>
  </div>
</template>
<script>
import axios from "axios";

export default {
  props: ["storage", "sizeData"],
  data() {
    return {
      stockObject: {
        sizename: null,
        price: null,
        stock: null,
      },
      stockObject2: {
        sizename: null,
        price: null,
        stock: null,
      },
      addUnitDialogue: false,
      editDialogue: false,
      addSizeDialogue: false,
      newSize: null,
      newPrice: null,
      newStock: 0,
      colorStatus: false,
      newColor: null,
      // productStock: [],
      // units:[],
      selectedStock: {},
      newType: null,
      addProductDialogue: false,
      productType: null,
      productTypes: [],
      productSizes: [],
      productSizeArray: [],
      // ````````````````````
      // ````````````````````
      // ````````````````````
      firstDialog: false,
      firstEditDialog: false,
      secondDialog: false,
      isSubCheck: false,
      typeObject: {
        type: null,
        price: null,
        stock: null,
        weight: null,
        size: [],
      },
      sized: [],
      subtypeObject: {
        sizename: null,
        price: null,
        stock: null,
        weight: null,
      },
      units: [],
      flaggy: false,
      flagmain: false,
      subflag: false,
      editSubItem: {},
      editMainItem: {},
      editDialog: false,
      pos: null,
      major: null,
      addMoreSubDialog: false,
      firstForm: false,
      msg: null,
      showSnackBar: false,
      timeout: 5000,
      // ````````````````````
      // ````````````````````
    };
  },
  computed: {
    unitss() {
      if (this.storage) {
        //  var units={}
        //   for (var i = 0; i < this.storage.length; i++) {
        //   var obj = {};
        //   obj.value = this.storage[i];
        //   obj.disabled = false;
        //   units.push(obj);
        // }
        return this.storage;
      } else return {};
    },
    productStock() {
      if (this.sizeData.length > 0) {
        return this.sizeData;
      } else return [];
    },
  },
  mounted() {
    if(this.sizeData) {
    if (this.sizeData.length > 0) this.units = this.sizeData;

    }
    // this.getTypeList();
    // this.getSizeList();
  },
  watch: {
    sizeData() {
      if (this.sizeData.length > 0) {
        this.flagmain = true;
        this.units = this.sizeData;
        // console.log(this.units)
      }
    },
    units() {
      this.$emit("stepper", {
        unitUpdate: this.units,
      });
    },

    // productStock: {
    //   handler: function () {
    //     this.$emit("stepper", {
    //       productStock: this.productStock,
    //       type: "edit",
    //     });
    //   },
    //   deep: true,
    // },
    isSubCheck() {
      if (this.isSubCheck) {
        this.typeObject.price = null;
        this.typeObject.stock = null;
        this.typeObject.weight = null;
        this.typeObject.size = [];
      }
      if (!this.isSubCheck) {
        this.typeObject.size = [];
      }
    },
  },

  methods: {
    validateFirst() {
      if (!this.isSubCheck) {
        if (
          this.typeObject.type &&
          this.typeObject.price &&
          this.typeObject.stock &&
          this.typeObject.weight
        ) {
          this.addTypeObject();
        } else {
          this.msg = "Please fill all details!";
          this.showSnackBar = true;
        }
      } else {
        if (this.typeObject.size.length <= 0) {
          this.msg = "Please add subtype details!";
          this.showSnackBar = true;
        } else this.addTypeObject();
      }
    },
    validateSecond() {
      if (this.isSubCheck) {
        // console.log("lengONE",this.typeObject.size.length)

        if (this.typeObject.size.length == 0) {
          if (
            this.subtypeObject.sizename &&
            this.subtypeObject.price &&
            this.subtypeObject.stock &&
            this.subtypeObject.weight
          ) {
            this.addSubTypeObject();
          } else {
            // console.log("leng",this.typeObject.size.length)
            this.msg = "Please fill!";
            this.showSnackBar = true;
          }
        } else {
          if (
            this.subtypeObject.sizename &&
            this.subtypeObject.price &&
            this.subtypeObject.stock &&
            this.subtypeObject.weight
          ) {
            this.addSubTypeObject();
          } else {
            // console.log("leng",this.typeObject.size.length)
            this.msg = "Please fill!";
            this.showSnackBar = true;
          }

          // alert("HAAHH");
        }

        // if (
        //   this.subtypeObject.sizename &&
        //   this.subtypeObject.price &&
        //   this.subtypeObject.stock &&
        //   this.subtypeObject.weight
        // ) {
        //   this.addSubTypeObject();
        // } else {
        //   this.msg = "Please fill all sub details!";
        //   this.showSnackBar = true;
        // }
      }
    },
    getTypeList() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/producttype/getlist",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          // if (response.data.package) this.pricing = response.data.package;
          if (response.data.status) {
            this.productTypes = response.data.data;
          } else {
            this.msg = response.data.msg;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getSizeList() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/productsize/getlist",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status) {
            this.productSizes = response.data.data;
          } else {
            this.msg = response.data.msg;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    editStock() {
      for (var i = 0; i < this.productStock.length; i++) {
        for (var j = 0; j < this.productStock[i].size.length; j++) {
          if (this.productStock[i].size[j]._id == this.stockObject2._id) {
            this.productStock[i].size[j] = JSON.parse(
              JSON.stringify(this.stockObject2)
            );
          }
        }
      }
      this.$emit("stepper", {
        productStock: this.productStock,
      });
      this.editDialogue = false;
    },
    // New adder
    // New adder
    mystockAdder(item) {
      if (item == "new") {
        this.firstDialog = true;
      } else {
        this.firstDialog = false;
      }
    },
    addTypeObject() {
      this.units.push(JSON.parse(JSON.stringify(this.typeObject)));
      // console.log("Filled Com types: ", this.units);
      this.flagmain = true;
      this.isSubCheck = false;
      this.firstDialog = false;
      this.typeObject.type = null;
      this.typeObject.price = null;
      this.typeObject.stock = null;
      this.typeObject.weight = null;
      this.typeObject.size = [];
    },
    addSubTypeObject() {
      this.typeObject.size.push(JSON.parse(JSON.stringify(this.subtypeObject)));
      // this.sized.push(JSON.parse(JSON.stringify(this.subtypeObject)));
      // console.log("Finale Sub: ", this.typeObject);
      this.flaggy = true;
      this.secondDialog = false;

      this.subtypeObject = {};
    },
    editSub(item, pos, major) {
      this.editSubItem = JSON.parse(JSON.stringify(item));
      this.pos = pos;
      this.major = major;
      this.editDialog = true;
    },
    editSubTypeObject() {
      if (
        this.editSubItem.sizename &&
        this.editSubItem.stock &&
        this.editSubItem.price &&
        this.editSubItem.weight
      ) {
        this.units[this.major].size[this.pos] = JSON.parse(
          JSON.stringify(this.editSubItem)
        );
        this.editDialog = false;
      } else {
        this.msg = "Please fill all fields";
        this.showSnackBar = true;
      }
    },
    addMoreSub(major) {
      this.major = major;
      this.secondDialog = true;
      this.subflag = true;
    },
    addMoreSubTypeObject() {
      if (
        this.subtypeObject.sizename &&
        this.subtypeObject.stock &&
        this.subtypeObject.price &&
        this.subtypeObject.weight
      ) {
        this.units[this.major].size.push(
          JSON.parse(JSON.stringify(this.subtypeObject))
        );
        // this.typeObject.size.push(JSON.parse(JSON.stringify(this.subtypeObject)))
        this.secondDialog = false;
        this.subtypeObject = {};
      } else {
        this.msg = "Please fill all fields";
        this.showSnackBar = true;
      }
    },
    editMain(item, major) {
      this.editMainItem = JSON.parse(JSON.stringify(item));
      this.major = major;
      this.firstEditDialog = true;
    },
    editTypeObject() {
      // this.units.push(JSON.parse(JSON.stringify(this.typeObject)));
      if (this.editMainItem.size.length <= 0) {
        if (
          this.editMainItem.type &&
          this.editMainItem.stock &&
          this.editMainItem.price &&
          this.editMainItem.weight
        ) {
          this.units[this.major] = JSON.parse(
            JSON.stringify(this.editMainItem)
          );
          this.firstEditDialog = false;
        } else {
          this.msg = "Please fill all fields";
          this.showSnackBar = true;
        }
      } else {
        if (this.editMainItem.type) {
          this.units[this.major] = JSON.parse(
            JSON.stringify(this.editMainItem)
          );
          this.firstEditDialog = false;
        } else {
          this.msg = "Please fill all only fields";
          this.showSnackBar = true;
        }
      }
    },
    reUp() {
      if (this.typeObject.size.length > 0) {
        console.log("aa");
      }
    },
    // End new  adder
    // End new  adder
    // End new  adder
    // End new  adder

    stockAdder(item) {
      if (item == "new") {
        this.addSizeDialogue = true;
      } else {
        this.addSizeDialogue = false;
        this.selectedStock = item;
      }
      this.addProductDialogue = true;
    },

    addSizeStock() {
      this.productSizeArray.push(JSON.parse(JSON.stringify(this.stockObject)));
      // this.productStock.push(JSON.parse(JSON.stringify(this.stockObject)));
      // this.$emit("stepper", {
      //   productStock: this.productStock,
      // });
      this.addUnitDialogue = false;
    },
    addFinalStock() {
      this.productStock.push({
        type: this.productType,
        size: this.productSizeArray,
      });
      this.productType = null;
      this.productSizeArray = [];
      this.$emit("stepper", {
        productStock: this.productStock,
      });
      this.addUnitDialogue = false;
      this.addProductDialogue = false;
    },
  },
};
</script>
<style >
.stockSizeSelector.v-text-field.v-text-field--solo .v-input__control {
  min-height: 50px !important;
  padding: 0 !important;
  border-radius: 5px !important;
}
</style>
